import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {updateStepData} from "../../redux/actions/actions";
import {ButtonContainer} from "../ButtonContainer/ButtonContainer";

export const MeasureSlide = ({nextStep, prevStep, title, text, url, stepNumber}) => {
    const stepData = useSelector(state => state.stepsData.filter(stepData => stepData.stepNumber === stepNumber)[0]);
    const dispatch = useDispatch();

    const onChange = (e) => {
        const newStepData = {...stepData};
        newStepData[e.target.id] = e.target.value;
        dispatch(updateStepData(stepNumber, newStepData));
    };

    const showNextButton = () => stepData.inputNumber !== "";

    return (
        <React.Fragment>
            <div class="fadeIn">
                <h1 className="measureTitle">{title}</h1>
                <div className="measureWrapper">
                    <iframe title="title" allowfullscreen="allowfullscreen" className='video' src={url} />
                    <div className="measureInfo">
                        <p className="measureText">{text}</p>
                            <div className="measureInputs">
                                <div className="measureLeft">
                                    <div className="measureInput">
                                        <input  id="inputNumber" value={stepData.inputNumber} onChange={onChange}/> <span>см</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <ButtonContainer prevStep={prevStep} nextStep={nextStep} showNextButton={showNextButton()}  disableNextButton={false}/>
    </React.Fragment>
    )
};
        