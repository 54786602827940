import {NEXT_STEP, PREV_STEP, UPDATE_STEP_DATA, FINAL_STEP, FIRST_STEP} from "../actions/actions";

const initialState = {
    currentStep: 0,
    countSteps: 6,
    stepsData: [
        {
            stepNumber: 1,
            checkBoxValues: [false, false, false, false, false]
        },
        {
            stepNumber: 2,
            inputNumber: ""
        },
        {
            stepNumber: 3,
            inputNumber: "",
        },
        {
            stepNumber: 4,
            inputNumber: "",
        }
    ]
};

export const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEXT_STEP: {
            if (state.currentStep < state.countSteps) {
                return {
                    ...state,
                    currentStep: state.currentStep + 1
                }
            }
            return state;
        }

        case PREV_STEP: {
            return {
                ...state,
                currentStep: state.currentStep - 1
            }
        }

        case UPDATE_STEP_DATA: {
            return {
                ...state,
                stepsData: state.stepsData.map(stepData => stepData.stepNumber === action.stepNumber ? action.stepData : stepData)
            }
        }


        case FIRST_STEP: {
            return {
                ...state,
                currentStep: 0
            }
        }

        default: {
            return state;
        }
    }
};
