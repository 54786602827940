import React from 'react';
import './App.css';
import { StepsController } from './StepsController';


export const App = () => {

    return (
        <div className="App">
        	<div className="logoWrapper">
            	<a href="http://bbrfactory.com/" target="_blank">
            		<img className="logo" src="images/logo.svg" alt="logo"/>
            	</a>
            </div>
            <StepsController />
        </div>
    );
};

