import React from 'react';
import { Button } from './../Button';

export const WelcomeSlide = ({nextStep}) => {
    return (
        <React.Fragment>
            <div className="firstSlide">
                <div>
                    <div className="fadeIn">
                        <h1>Сверятель мерок</h1>
                        <p>Чтобы правильно выбрать размер, который вам подойдёт, нужно измерить три величины: длину, обхват пучков и подъем. Это займёт 5 минут вашего времени.</p>
                    </div>
                    <div className="button-container">
                        <Button onClick={nextStep} isNext={true}>Начать</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

