import React from 'react';
import { Button } from '../Button';


export const ButtonContainer = ({ prevStep, nextStep, disableNextButton, showNextButton }) => {
    // const componentStyle = showNextButton ? "next-button" : "next-button go" 
    return (
        <React.Fragment>
            <div className="button-container">
                <Button onClick={prevStep} isNext={false} showNextButton={showNextButton}>Назад</Button>
                {/* <div className="componentStyle"> */}
                {<Button onClick={nextStep} isNext={true} disabled={disableNextButton} showNextButton={showNextButton}>Далее</Button>}
                {/* </div> */}
            </div>
        </React.Fragment>
    );
};
