import React from 'react';
import {useSelector} from "react-redux";
import {ButtonContainer} from "../ButtonContainer/ButtonContainer";
import {SimpleButton, Button} from "../Button";

export const FinalSlide = ({prevStep, firstStep}) => {
    const stepsData = useSelector(state => state.stepsData);
    const formResultData = () => {

        let result = `Длина ноги: ${stepsData[1].inputNumber}\nОбхват:  ${stepsData[2].inputNumber}\nПодъём:  ${stepsData[3].inputNumber}`;

        return result;
    };

    return (
        <React.Fragment>
            <div className="fadeIn">
                <h1>Мерки сняты, спасибо</h1>
                <p className="finalText">Сравните мерки своей ноги с мерками размера в интернет-магазине. При подборе размера старайтесь, чтобы мерка вашей ноги была ближе к середине промежутка.</p>
                <textarea id="textArea" className="resultTextArea">
                    {formResultData()}
                </textarea>
                <div class="finalLinks">
                    <p>Есть вопросы? <br /><a class="finalLink" href="https://vk.com/bikerbootsrussia" target="_blank">Напишите нам</a></p>
                    <p>Нестандартная нога? <br /><a class="finalLink" href="http://bbrfactory.com/" target="_blank">Индивидуальный пошив</a> </p>
                </div>
            </div>
            <ButtonContainer showNextButton={false} prevStep={prevStep}/>
        </React.Fragment>
    );
};
