import React from 'react';

export const Button = (props) => {

    if (!props.isNext && props.showNextButton) {
        let style = "prev-button short";
        return (
            <div onClick={props.onClick}>
                <button className={style} disabled={props.disabled} >
                    {!props.isNext && <img src="/images/arrow.svg" alt=''/>}
                    {props.children}
                </button>
            </div>
        )
    }

    if (props.isNext && props.showNextButton) {
        let style = "next-button go";
        return (
            <div onClick={props.onClick}>
                <button className={style} disabled={props.disabled} >
                    {!props.isNext && <img src="/images/arrow.svg" alt='' />}
                    {props.children}
                </button>
            </div>
        )
    }

    if (!props.isNext) {
        let style = "prev-button";
        return (
            <div onClick={props.onClick}>
                <button className={style} disabled={props.disabled} >
                    {!props.isNext && <img src="/images/arrow.svg" alt='' />}
                    {props.children}
                </button>
            </div>
        )
    }

    if (props.isNext) {
        let style = "next-button";
        return (
            <div onClick={props.onClick}>
                <button className={style} disabled={props.disabled} >
                    {!props.isNext && <img src="/images/arrow.svg" alt='' />}
                    {props.children}
                </button>
            </div>
        )
    }

};

export const SimpleButton = (props) => {

    return (
        <div onClick={props.onClick}>
            <button className="again-button" disabled={props.disabled} >{props.children}</button>
        </div>
    )
};
